import React from "react";
import Layout from "../components/layout";
import Text from "../components/SVG/TextDivider";
import { graphql } from "gatsby";
import * as styles from "../styles/templates/galleryTemplate.module.css";
import { GatsbyImage } from "gatsby-plugin-image";
import BreadCrumb from "../components/BreadCrumb";

function Gallery({ data }) {
  var NumberofColumns = 4;
  var Slices = [];
  var Length = data.strapiGallery.Images.length;
  var ItemsPerColumn = Math.floor(Length / NumberofColumns);
  var Remainder = Length - ItemsPerColumn * NumberofColumns;
  var startIndex = 0;

  for (var i = 0; i < NumberofColumns; i++) {
    var Incrementer = ItemsPerColumn;
    if (i + 1 <= Remainder) Incrementer = ItemsPerColumn + 1;
    Slices[i] = data.strapiGallery.Images.slice(
      startIndex,
      startIndex + Incrementer
    );
    startIndex += Incrementer;
  }

  return (
    <Layout
      title={`${data.strapiGallery.Category} Gallery images`}
      description={`${data.strapiGallery.Category} Gallery images by N.K. Caterers`}
    >
      <Text containerStyle={{ marginTop: "4rem", paddingBottom: `2rem` }}>
        {data.strapiGallery.Category}
      </Text>
      <BreadCrumb text="Gallery" to="/gallery" />
      <div className={styles.container}>
        {Slices.map((images, index) => {
          return (
            <div key={index} className={styles.column}>
              {images.map((image) => {
                return (
                  <GatsbyImage
                    key={image.imageFile.id}
                    className={styles.image}
                    image={image.imageFile.childImageSharp.gatsbyImageData}
                    alt=""
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </Layout>
  );
}
export const query = graphql`
  query galleryQuery($Category: String!) {
    strapiGallery(Category: { eq: $Category }) {
      Category
      Images {
        imageFile {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export default Gallery;
